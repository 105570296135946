<template>
  <div class="projects">
        <div class="header_pic">
        <div class="text">
            <h1>פרוייקטים</h1>
            <p>תמונות מהפרוייקטים האחרונים של האדריכלית ומעצבת הפנים רינת אשכנזי</p>
        </div>
    </div>

    <div class="center">
      <!-- <div class="header">          
          <div class="options">
            <li><a href="#">ציבוריים</a></li>
            <li><a href="#">בתים ודירות</a></li>
            <li><a href="#">הכל</a></li>
          </div>
      </div> -->

      <div class="row nopadding">
        <div class="projects_pics col-lg-4 col-md-6 col-sm-6 " v-for="item in items" :key="item.id">
          <router-link :to="'/project/'+item.id">
            <div class="project_pic"> 
              <span :style="'background-image:url('+ item.gallery[0].pic_parsed+')'"></span>
            </div>
            <h3 v-html="item.name"></h3>
            <p v-html="item.location"></p>
          </router-link>
          <div class="clr"></div>
        </div>
       </div>
      <div class="clr"></div>

    </div>
  </div>
</template>

<script>
export default {
      components: {},
      data(){
        return{
          items:[],
        }
      },
      mounted(){
        this.net_api({ action: 'projects/results' }, (data) => { 
         this.items = data.data.items
        });
      }
}
</script>

<style>
    .projects_pics:nth-child(2n+1){clear: both;}

</style>
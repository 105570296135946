<template>
  <div class="home">
    <!-- <div dir="rtl"> -->
    <carousel
      :per-page="1"
      :loop="true"
      :autoplay="true"
      :mouseDrag="false"
      :touchDrag="false"
      :autoplayHoverPause="false"
      :paginationEnabled="true"
      :autoplayTimeout="2500"
      :rtl="true"
      :easing="true"
    >
      <slide class="slider" v-for="item in items" :key="item.id">
        <div
          class="carusel_item"
          :style="'background-image:url(' + item.pic_parsed + ')'"
        >
          <div class="slider_text">
            <h1>רינת אשכנזי</h1>
            <div class="line"></div>
            <h3>אדריכלית ומעצבת פנים</h3>
            <button><a href="/projects">לפרוייקטים</a></button>
          </div>
        </div>
      </slide>
    </carousel>
    <!-- </div> -->
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "Home",
  components: { Carousel, Slide },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.net_api({ action: "home_images" }, (data) => {
      this.items = data.data;
    });
  },
};
</script>
<style>
@media (max-width: 414px) {
  .carusel_item {
    height: 100vh;
  }
}
</style>

<template>
<div class="project">
    <div class="header_pic">
        <div class="text">
            <h1>{{project.name}}</h1>
            <h3>{{project.location}}</h3>
        </div>
    </div>

    <div class="centered">
        <div class="pro_text col-md-6 col-xs-6">
            <h2 v-html="project.description"></h2>
            <p v-html="project.content"></p>
   
            <div class="social_links">
                <a href="https://www.facebook.com/ashrinat">
                    <div class="icon soci_fb"></div>
                </a>
                <a href="https://twitter.com/share?text=%D7%90%D7%93%D7%A8%D7%99%D7%9B%D7%9C%D7%95%D7%AA+%D7%95%D7%A2%D7%99%D7%A6%D7%95%D7%91+%D7%A4%D7%A0%D7%99%D7%9D+%26%238211%3B+%D7%93%D7%99%D7%A8%D7%94&url=http%3A%2F%2Fwww.rinatashkenazi.co.il%2Fportfolio%2F%25d7%2590%25d7%2593%25d7%25a8%25d7%2599%25d7%259b%25d7%259c%25d7%2595%25d7%25aa-%25d7%2595%25d7%25a2%25d7%2599%25d7%25a6%25d7%2595%25d7%2591-%25d7%25a4%25d7%25a0%25d7%2599%25d7%259d-%25d7%2593%25d7%2599%25d7%25a8%25d7%2594-3%2F"><div class="icon soci_tw"></div>
                </a>
                <a href="mailto:rinat042@gmail.com">
                    <div class="icon soci_em"></div>
                </a>
            </div>
        </div>

         <div class="wrapper_pic col-md-6 col-xs-6">
            <div class="wrapper_sim" v-if="simA" >
                <div class="slider_pic" :style="{ maxWidth: '650px'}">
                    <VueCompareImage :sliderPositionPercentage="0.5" 
                    :leftImage="project.simulationsA[0].pic_parsed" 
                    :rightImage="project.simulationsA[1].pic_parsed"  />
                </div>

            
             <div class="wrapper_sim" v-if="simB" >
                <div class="slider_pic" :style="{ maxWidth: '650px'}">
                    <VueCompareImage :sliderPositionPercentage="0.5" 
                    :leftImage="project.simulationsB[0].pic_parsed" 
                    :rightImage="project.simulationsB[1].pic_parsed"  />
                </div>
            </div>

            <div class="wrapper_sim" v-if="simC" >
                <div class="slider_pic" :style="{ maxWidth: '650px'}">
                    <VueCompareImage :sliderPositionPercentage="0.5" 
                    :leftImage="project.simulationsC[0].pic_parsed" 
                    :rightImage="project.simulationsC[1].pic_parsed"  />
                </div>
            </div>
        </div>

            <div class="projects_pics">
                <div class="project_pic" v-for="item in project.gallery" :key="item.id">
                    <span :style="'background-image:url('+item.pic_parsed+')'"></span>
                </div>

            </div>
        </div>
        <div class="clr"></div>
    </div>


</div>
</template>
<script>
import VueCompareImage from 'vue-compare-image';
export default {
    components: { VueCompareImage },
    data(){
        return{ 
            id: this.$route.params.id,
            project:{},
            simA:false,   
            simB:false, 
            simC:false,   
        }
    },
    methods:{

    },
    mounted(){
            this.net_api({ action: 'projects/results',data:{id:this.id} }, (data) => { 
            this.project = data.data.items[0]
            if(this.project.simulationsA.length > 1 )
            {
                this.simA = true
            } if(this.project.simulationsB.length > 1)
            {
                this.simB = true
            } if(this.project.simulationsC.length > 1)
            {
                this.simC = true
            }
        });
        // alert(this.id)
        //API

    }
}
</script>
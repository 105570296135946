import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

import store from "./store";
import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);

import { LightGallery } from "vue-light-gallery";
Vue.use(LightGallery);
import VueResource from "vue-resource";
Vue.use(VueResource);
/*
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)
*/
import VueMeta from "vue-meta";

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
Vue.use(VueMaterial);

import "./general.scss";
import "./app.scss";

window.api_url = "https://admin.rinatashkenazi.com/api_vue/";

if (window.location.href.indexOf("http://localhost") === -1) {
  document.domain = "rinatashkenazi.com";
}
Vue.mixin({
  data() {
    return {};
  },
  computed: {
    user() {
      if (!this.isNull(localStorage.getItem("user_token"))) {
        return true;
      }

      return false;
    },
  },

  methods: {
    is_mobile() {
      return (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        // || navigator.userAgent.match(/iPad/i)
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i)
      );
    },
    get_me() {
      return this.$store.user;
    },
    format_number: function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    isNull: function(str) {
      return str == "" || str == null || str == undefined;
    },
    pushIfNotExist(element, comp) {
      if (element.indexOf(comp) == -1) {
        element.push(comp);
      }
    },
    pushOrPull(element, comp) {
      var index = element.indexOf(comp);
      if (index == -1) {
        element.push(comp);
      } else {
        element.splice(index, 1);
      }
    },
    inArray(element, comp) {
      return element.indexOf(comp) != -1;
    },

    net_api: function(settings, func, failFunc) {
      // var is_test = false;

      var headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      if (!this.isNull(localStorage.getItem("user_token"))) {
        headers.Authorization = localStorage.getItem("user_token");
      }

      // if (!is_test)
      // {
      if (settings.method == "get") {
        this.$http
          .get(window.api_url + settings.action, settings.data, {
            emulateJSON: true,
            headers: headers,
          })
          .then(
            (response) => {
              if (response.body == "error") {
                localStorage.setItem("user_token", "");
                document.location.reload();
              }
              func(response.body);
            },
            (response) => {
              if (!this.isNull(failFunc)) failFunc(response);
              else func(false);
            }
          );
      } else {
        this.$http
          .post(window.api_url + settings.action, settings.data, {
            emulateJSON: true,
            headers: headers,
          })
          .then(
            (response) => {
              if (response.body == "error") {
                localStorage.setItem("user_token", "");
                document.location.reload();
              }
              func(response.body);
              //console.log(response.body);
            },
            (response) => {
              if (!this.isNull(failFunc)) failFunc(response);
              else func(false);
              //console.error(response.body);
            }
          );
      }
    },

    open_chat(id) {
      if (window.appVue.open_chats.indexOf(id) === -1) {
        window.appVue.open_chats.push(id);
      }
    },
  },
});

new Vue({
  router,
  store: store,
  render: (h) => h(App),
}).$mount("#app");

<template>
    <div class="footer col-md-12 col-xs-12 nopadding">
        <footer>
            <div class="wrapper_footer">
                <div class="col-md-6 col-xs-8">
                    <div class="con_foo">
                        <h3>צור קשר</h3>
                        <li>052-746-77-70</li> 
                        <li>rinat042@gmail.com</li>
                            <div class="social_links">
                                <a href="https://www.facebook.com/ashrinat">
                                    <div class="icon soci_fb"></div>
                                </a>
                                <a href="https://twitter.com/share?text=%D7%90%D7%93%D7%A8%D7%99%D7%9B%D7%9C%D7%95%D7%AA+%D7%95%D7%A2%D7%99%D7%A6%D7%95%D7%91+%D7%A4%D7%A0%D7%99%D7%9D+%26%238211%3B+%D7%93%D7%99%D7%A8%D7%94&url=http%3A%2F%2Fwww.rinatashkenazi.co.il%2Fportfolio%2F%25d7%2590%25d7%2593%25d7%25a8%25d7%2599%25d7%259b%25d7%259c%25d7%2595%25d7%25aa-%25d7%2595%25d7%25a2%25d7%2599%25d7%25a6%25d7%2595%25d7%2591-%25d7%25a4%25d7%25a0%25d7%2599%25d7%259d-%25d7%2593%25d7%2599%25d7%25a8%25d7%2594-3%2F"><div class="icon soci_tw"></div>
                                </a>
                                <a href="mailto:rinat042@gmail.com">
                                    <div class="icon soci_em"></div>
                                </a>
                            </div>
                    </div>
                </div>
                <div class="logo_footer col-md-6 col-xs-4">
                    <div class="logo"></div> 
                </div>
            </div>
        </footer> 
    </div>
  
</template>

<script>
export default {

}
</script>

<style>

</style>
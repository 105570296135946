<template>
<div class="contact">
  <div class="header_pic">
    <div class="text">
      <h1>צור קשר</h1>
      <p>מלאו את הטופס או התקשרו 052-7467770</p>
    </div>
  </div>

  <div class="centered">
    <div class="col-md-12">
      <div class="card col-md-6">
         <div class="card_pic"></div>
      </div>

            <div class="form col-md-6" v-if="success">
                <div class="wrapper_su">
                  <div class="check_pic"></div>
                  <h2>תודה!</h2>
                  <h3>הפנייה התקבלה, אחזור אליכם בהקדם.</h3>
                  <router-link to="/">
                    <button> לעמוד הבית</button>
                  </router-link>
                </div>
            </div>

            <div class="form col-md-6" v-if="!success">
                <form @submit.prevent="checkForm">
                  <p class="errors" v-if="errors.length">
                    <!-- <b>יש למלא את טופס פנייה תקין</b> -->
                    <ul>
                      <div class="error_pic"></div>
                      <li v-for="error in errors" :key="error">{{error}}</li>
                    </ul>
                  </p>
                  <md-field>
                    <label>שם מלא</label>
                    <md-input type="text" name="name" v-model="form.name"></md-input>
                  </md-field>

                  <md-field>
                    <label>אימייל</label>
                    <md-input  type="email" name="email" v-model="form.email"></md-input>
                  </md-field>

                  <md-field>    
                    <label>מס' טלפון</label>
                    <md-textarea md-autogrow type="text" name="tel" v-model="form.tel"></md-textarea>
                  </md-field>

                  <md-field>
                    <label>תוכן</label>
                    <md-textarea md-counter="200" maxlength="200" type="text" name="msg" v-model="form.msg"></md-textarea>
                  </md-field>

                  <md-button class="md-raised btn" type="submit">שלח</md-button>
                </form>
            </div>
          </div>
      </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
        errors: [],
        form:{
          name: null,
          email:null,
          tel:null,
          msg:null
        },
        success:false
    }
  },
  methods: {
    checkForm: function(e){
      if(this.form.name && this.form.email && this.form.tel && this.form.msg) {
        this.net_api({ action: 'contact' ,data:{form:this.form}}, (data) => { 
         if(data.data)
         {
           this.success = true
         }
        });
      }

      this.errors = [];

      if(this.isNull(this.form.name)){
        this.errors.push('נדרש למלא שם.');
      }
      if(this.isNull(this.form.email)){
        this.errors.push('נדרש למלא אימייל.');
      }
      if(this.isNull(this.form.tel)){
        this.errors.push('נדרש למלא מספר טלפון.');
      }
      if(this.isNull(this.form.msg)){
        this.errors.push('נדרש למלא את תוכן הפנייה.');
      }
      e.preventDefault();

    }
  }
}
</script>

<style>

</style>
<template>
  <div class="recommends">
    <div class="header_pic">
        <div class="text">
            <h1>לקוחות ממליצים</h1>
        </div>
    </div>

    <div class="centered">

        <div class="col-md-12 col-xs-12" v-for="item in items" :key="item.id">
            <div class="thanks_box" >
                <div class="logo_colorful col-md-6 col-xs-6"></div>
                <div class="item col-md-8 col-xs-8" >
                    <h3 v-html="item.title"></h3>
                    <p v-html="item.content"></p>
                    <small v-html="item.name"></small>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
export default {
      data(){
        return{
          items:[],
        }
      },
      mounted(){
        this.net_api({ action: 'rinat_recom' }, (data) => { 
         this.items = data.data
        });
      }
}
</script>

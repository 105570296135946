<template>
  <div id="app">
    <!-- <div class="hum" @click="menu_open = !menu_open"></div> -->
    <div
      id="hamburger"
      onclick="this.classList.toggle('open');"
      v-if="is_mobile()"
      @click="menu_open = !menu_open"
    >
      <svg width="100" height="60" viewBox="0 0 100 100">
        <path
          class="line line1"
          d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
        />
        <path class="line line2" d="M 20,50 H 80" />
        <path
          class="line line3"
          d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
        />
      </svg>
    </div>
    <div id="nav">
      <div class="wrapper_nav" v-if="!is_mobile()">
        <div id="content_home">
          <li><router-link to="/">עמוד הבית</router-link></li>
        </div>
        <li><router-link to="/projects">פרוייקטים</router-link></li>
        <li><router-link to="/about">אודות</router-link></li>
        <li><router-link to="/recommend">המלצות</router-link></li>
        <li><router-link to="/contact">צור קשר</router-link></li>
      </div>

      <div
        :class="'mobile_menu ' + (menu_open ? 'open' : '')"
        v-if="is_mobile()"
      >
        <ul>
          <li @click="menu_open = !menu_open">
            <router-link to="/">עמוד הבית</router-link>
          </li>
          <li @click="menu_open = !menu_open">
            <router-link to="/projects">פרוייקטים</router-link>
          </li>
          <li @click="menu_open = !menu_open">
            <router-link to="/about">אודות</router-link>
          </li>
          <li @click="menu_open = !menu_open">
            <router-link to="/recommend">המלצות</router-link>
          </li>
          <li @click="menu_open = !menu_open">
            <router-link to="/contact">צור קשר</router-link>
          </li>
        </ul>
      </div>
    </div>

    <router-link to="/"><div class="logo_pic"></div></router-link>
    <router-view />

    <Footer />
  </div>
</template>

<script>
import Footer from "./views/footer";

export default {
  metaInfo: {
    title: "רינת אשכנזי אדריכלות",
    description: "רינת אשכנזי אדריכלות",
    meta: [
      { charset: "utf-8" },
      { name: "title", content: "רינת אשכנזי, אדריכלות ועיצוב פנים" },
      { name: "description", content: "הנדסאית אדריכלות ומעצבת פנים" },
      {
        name: "keywords",
        content:
          "עיצוב פנים, אדריכלות, אדריכלות ועיצוב פנים, רינת אשכנזי, אדריכלית, מעצבת פנים, רינת אשכנזי אדריכלית, רינת אשכנזי אדריכלית ומעצבת פנים",
      },
    ],
  },
  components: {
    Footer,
  },
  data() {
    return {
      menu_open: false,
    };
  },
  methods: {},
  mounted() {
    console.log(this.is_mobile);
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Heebo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding-top: 80px;

  #content {
    min-height: 76vh;
  }
}
.mobile_menu {
  height: 100%;
  width: 200px;
  position: absolute;
  top: 80px;
  right: -350px;
  transition: all 0.8s;
  z-index: 0;
  width: 80%;
  ul li {
    list-style: none;
    padding: 10px 10px 15px 0px;
  }
}
.mobile_menu.open {
  right: 0;
  background: #242323;
  height: 100vh;
  width: 165px;
  z-index: 222;
  top: 0px;
  overflow: hidden;
  ul {
    padding: 0px;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    li {
      color: #fff;
      list-style-type: none;
      padding: 10px 10px 15px 0px;
    }
  }
}
</style>

<template>
  <div class="about">
      <div class="header_pic">
      <div class="centered">
          <div class="about_text">
              <h1>רינת אשכנזי - המשרד</h1>
              <p>המשרד של האדריכלית ומעצבת הפנים רינת אשכנזי הוקם בשנת 2008, אחרי קריירה רבת שנים ופעילות ארוכה בחברות המובילות בארץ בענף הבניין. למעלה מעשור רינת אשכנזי מתמקדת באדריכלות ועיצוב פנים של מבני דירות דופלקס ופנטאוז, דירות בבניינים, משרדים, עיצוב ובשיפוץ מתמשך של חללים גדולים, מוסדות ובניינים. רינת אשכנזי אוהבת המקצוע, היצירתיות והקשר האנושי. רינת גאה ליצור ללקוחותיה את המקום אליו הם נהנים ומתרגשים לחזור בסוף יום עבודה, עם הרגשה חמימה של תחושת ביטחון ונוחות.</p>
          </div>
      </div>
      </div>
    
    <div class="centered">
      <div class="main_t">
          <div class="col-md-12 nopadding">
              <div class="t_about col-md-12 nopadding">
                <h3>מקצועיות - נקודה</h3>
                <p>לרינת נסיון רב שנים בתחום כאדריכלית ומעצבת פנים בהבנת והתאמת צרכי הלקוח, למציאות בשטח ולאפשרויות העומדות לרשותו, תוך הקפדה על איכות המוצרים והתאמתם לדרישות האדריכליות, במסגרת עמידה בתקציב הלקוח המוגדר מראש.</p>

                <h3>ליווי, 360°</h3>
                <p>אתם לא לבד
                    רינת מקפידה על תשומת לב אישית ומעניקה ללקוחותיה תכנון הרמוני, מאוזן, בעל יופי ונוחות, תוך סבלנות והקשבה להתאמת השפה האדריכלית לטעמכם ולעולמכם הערכי, הבנת הצרכים והדרישות הייחודיים לכם.
                    רינת מלווה את לקוחותיה ברכישת מוצרי הבנייה, חיפויים, הזמנת עבודות הנגרות, בחירת הרהיטים ואביזרי העיצוב הסופיים לבית, כחלק אינטגרלי מתהליך העיצוב
                    ליווי 360 - הכולל סיוע בהתקשרויות עם:
                    קבלני משנה בעלי ניסיון, ותק ואמינות
                    ספקים ויועצים לתחומים שונים כגון מיזוג אויר, חשמל, נגרות, עבודות שיש ואבן
                    ובהתאם לצורך, סיוע ביבוא ישיר של מוצרים ואביזריי מעצבים מחו״ל</p>

                <h3>ידע ונסיון</h3>
                <p>מיטב הידע והניסיון של רינת בענף הבניין עומד לרשותכם ומאפשר לכם לעבור את התהליך בדרך היעילה, המקצועית והנוחה ביותר
                לרינת ידע נרחב בבניית ועיצוב בריכות שחיה, חדרי-כושר, מלתחות, שירותים והמבואות לחדרים אלו
                עמידה בתקנים המחמירים של מכון התקנים הישראלי ומשרד הבריאות. ללא פשרות ועם התאמה לצרכים ולדרישות הלקוח עם דגש על עיצוב יפהפה ומרהיב עין</p>
              </div>
              <div class="about_pic_top col-md-12 nopadding">
                  <div class="about_pic"></div>
              </div>
          </div>
      </div>


    <div class="main_d">
        <div class="col-md-12 main_d nopadding">
            <div class="t_about col-md-12 nopadding">
                <h3>התמחויות</h3>
                <p>האדריכלית רינת משלבת בעבודותה שיתופי פעולה עם קבלנים בבניה חדשה, ויזמים במסגרת התחדשות עירונית</p>

                <h4>שינויי דיירים בפרוייקטים חדשים</h4>
                <p>התאמת הדירה שנרכשה מהקבלן לצרכי הלקוח, הכנת סט תכניות: אדריכלות, ריהוט, חשמל, תאורה, אינסטלציה, גבס ומיזוג אוויר, התקשרות עם הקבלן להבנת התוכניות, פיקוח על ביצוע תוכנית הלקוח.</p>

                <h4>ליווי הלקוח מול אנשי מקצוע וספקים</h4>
                <p>הגשת תוכניות, מעקב ופיקוח אחר התקדמות הביצוע, תכנון מקצועי של המטבח, סיוע ברכישת כלים סניטאריים, דלתות פנים וחוץ, נגרות ועוד.</p>

                <h4>שיפוץ בית, דירה, משרד או עסק</h4>
                <p>ליווי השיפוץ והכנת סט תכניות: אדריכלות, חשמל, תאורה, אינסטלציה, גבס, מיזוג אוויר וריהוט. תיאום ופיקוח של אנשי המקצוע במהלך השיפוץ. תכנון מקצועי של המטבח, ליווי הלקוח מול הספקים השונים, ליווי לרכישת ריהוט ואביזרים עד ליצירת לסיום היצירה .</p>

                <h4>ליווי משלים ללקוח</h4>
                <p>ליווי הלקוח לרכישת רהיטים, תאורה ואביזרים, בחירת צבעים, חיפויים ואפקטים, ליצירת סגנון ומראה הרמוניים שמתאימים לסגנון שנבחר.</p>

                <h4>הום סטיילינג והלבשת הבית</h4>
                <p>רענון הבית ויצירת שינוי במראה מבלי לשבור קירות. יצירת מראה הרמוני ומעוצב בעזרת בחירת מערך צבעים, שילוב אביזרים מתאימים והוספת פריטים דקורטיביים. ארגון ושינוי חלקי הבית בהתאם לשינוי אורכות החיים .</p>

                <h4>השבחת נכס למכירה באמצעות הום סטייג׳ינג</h4>
                <p>עיצוב המותאם במיוחד למי שמעוניין להקל על מכירת נכס נדל״ני והעלאת ערכו באמצעות שינויים קטנים שעושים את כל ההבדל.</p>

                <h4>ליווי בקניית נכס חדש</h4>
                <p>תקצוב השיפוץ והשינוי בהתאם לצרכי הלקוח</p>

                <h4>עיצוב ותכנון משרדים ועסקים</h4>
                <p>עיצוב עבור עסקים ומשרדים גדולים וקטנים בהתאמה מדוייקת לצרכי הארגון. התהליך כולל הכנת סט תכניות: אדריכלות, חשמל, תאורה, אינסטלציה, גבס, מיזוג אויר, ריהוט ותכנון מטבח מקצועי. זאת, תוך אפיון צרכים מדוייק והתאמת העיצוב. ליווי לרכישת מוצרים כגון: סניטציה, דלתות פנים וחוץ, ליווי בבחירת ריהוט, תאורה, חיפויים, תמונות ואביזרים משלימים.</p>
            </div>
            <div class="about_pic_top col-md-12 nopadding">
                <div class="ri_pic"></div>
            </div>
        </div>
        <div class="clr"></div>
    </div>

        <div class="bottom_text">
            <h3>הבית שלכם</h3>
            <p>הבית הוא יצירה אדריכלית – התוצר הסופי משקף, הלכה למעשה, את החשיבה שעל בסיסה תוכנן.
            השתקפות האישיות של הלקוח והסביבה ושילובם מהווים את יסוד הפרוייקט תוך הקפדה על איכות, כבר ברמת הסקיצה, דרך תוכניות עבודה ועד לפרטים הקטנים בשילוב יצירתיות, השראה ודמיון.
            בניית בית – חלום אותו כל אחד רוצה להגשים. כמובן.
            הגעתם למצב בו אתם יכולים להגשים את חלומכם? ברכות!
            רינת תשמח לעמוד לשירותכם ומבטיחה לעשות את הכל באופן המקצועי וההוגן ביותר בכדי להגשים את חלומכם באוירה חיובית, חיונית ורגועה. תוך התאמה .מקסימלית ליכולותיכם ולצרכים שלכם</p>
        </div>
  </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
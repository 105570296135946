import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Projects from '../views/projects.vue'
import About from '../views/about.vue'
import Recommend from '../views/recommend.vue'
import project from '../views/project.vue'
import Contact from '../views/contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  { path: '/projects', name:'projects', component: Projects},
  { path: '/about', name:'about', component: About},
  { path: '/recommend', name:'recommend', component: Recommend},
  { path: '/contact', name:'contact', component: Contact},
  { path: '/project/:id', name:'project', component: project}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
